import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './Home'
import TaskDetail from './taskDetail/TaskDetail'
import Login from './login/index'
import Govern from './govern/govern'
import Detailed from './govern/detailed'
import BoxDet from './boxDet/boxDet'
import ShowArea from './demo/showArea'
// import Color from './demo/color'
import Example6 from './demo/Example6'
import './app.css'


import 'antd-mobile/dist/antd-mobile.css';

function App() {
  return (
    <Router>
      <div className="page">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/task/:id">
            <TaskDetail />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/hzl/login">
            <Govern />
          </Route>
          <Route path="/detailed">
            <Detailed />
          </Route>
          <Route path="/boxDet/:id">
            <BoxDet />
          </Route>
          <Route path="/showArea">
            <ShowArea />
          </Route>
          {/* <Route path="/color">
            <Color />
          </Route> */}
          <Route path="/example6">
            <Example6 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
