import React, { useState } from 'react'

import { Icon } from 'antd'

import 'antd/es/icon/style/css'

function Customer({ data = "" }) {
    const [phoneVisible, setVisible] = useState(false)
    const iconType = phoneVisible ? 'eye-invisible' : 'eye'

    const val = data.phone || ''
    return (
        <div className="task-customer">
            <div className="task-header">
                <span>客户信息</span>
                <Icon type={iconType} style={{ lineHeight: 0 }} onClick={() => { setVisible(!phoneVisible) }} />
            </div >
            <div className="task-row">
                姓名：{data.customerName}
            </div>
            <div className="task-row">
                电话：<span>{phoneVisible ? val : (val.substr(0, 3) + '****' + val.substr(8, 4))}&nbsp;&nbsp;</span>
            </div>
        </div>
    )
}
export default Customer