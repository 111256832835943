import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { Toast } from 'antd-mobile'
import {Redirect} from 'react-router-dom'
import MD5 from 'crypto-js/md5'

import logoImg from '../images/logo.png'
import { loginData } from '../api'

import './index.css'
class Index extends Component{
  constructor(props){
    super(props) //调用父类的构造函数，固定写法
    this.state={
      isLogin: false
    }
  }
  handleSubmit = e => {
    console.log('e: ', e)
    e.preventDefault();
    console.log('this: ', this.props.form)
    const form = this.props.form
    const val = form.getFieldsValue()
    console.log('val: ', val)
    console.log('kvvv: ', )
    loginData({loginName: val.username, password: MD5(val.password).toString()}).then(res => {
      console.log('res: ', res)
      if(res.resultMessageEnum === '0000') {
        Toast.success('登陆成功！', 1)
        localStorage.setItem("token", res.returnObject.token)
        this.setState({
          isLogin:true
        })
      } else {
        Toast.info('登陆失败', 1)
      }
  })
  }

  render () {
    if(this.state.isLogin) {   
      return <Redirect to='/'/>
    }

    const form = this.props.form
    const { getFieldDecorator } = form;

    return (
        <div className="login">
          <div className="top">
            <img src={logoImg} className="logo" alt="" />
            <span className="top-txt">嘀咕防疫机器人</span>
          </div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item className="item-box">
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="请输入11位手机号"
              />,
            )}
            </Form.Item>
            <Form.Item className="item-box" hasFeedback>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="请输入密码"
              />,
            )}
            </Form.Item>
            <Form.Item className="but-box">
              <Button type="primary" htmlType="submit" className="login-form-button">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
    )
  }
}

const indexLogin = Form.create()(Index)
export default indexLogin