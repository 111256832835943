import React, { useState, useEffect, useContext, useMemo } from 'react';
import {AreaContext, UPDATE_AREA} from './selectArea'

import { Picker, List, Button, Calendar } from 'antd-mobile';

import { getTopBoardData, getAreaList, exportMiniTaskExcel } from './api'
import dayjs from 'dayjs'

import './topboard.css'
import icon_arrows from './images/icon_arrows.png'
import icon_off from './images/icon_off.png'

const now = new Date();
const day = dayjs().format('YYYY-MM-DD')

function Board({ title, label, num, color }) {
    // console.log('title: ', title)
    // console.log('label: ', label)
    // console.log('color: ', color)
    let showNum, numColor
    if (parseInt(num) > 0) {
        showNum = '+' + num
        numColor = 'green'
    } else {
      numColor = 'red'
      showNum = num
    }
    
    return (
        <div className="board" onClick={() => {Hanlde(label)}}>
            <div className="board-title" style={{color}}>{title}</div>
            <div className="board-label">{label}</div>
            <div className="board-num">较昨日&nbsp;<span className={numColor}>{showNum}</span></div>
        </div>
    )
}
// 页面跳转
const Hanlde = (s) => {
  // console.log('s: ', s)
  if(s==='有感冒相关症状'){
    window.location.href = `/boxDet/1`
  }
  if(s==='未接通人数'){
    window.location.href = `/boxDet/3`
  }
  if(s==='建议人工跟进'){
    window.location.href = `/boxDet/2`
  }
}

const exportExcel = (code, startDate, endDate) => {
  const params = {
    code,
    startDate,
    endDate,
    userType: localStorage.getItem("userType") === '3'?1:0,
    allChild: 0
  }
  const userType = localStorage.getItem("userType") === '3'?1:0
  exportMiniTaskExcel(params).then(res => {
    // console.log('res: ', res)
  })
  window.location.href=`http://192.168.3.116:1080/services/miniProgram/exportMiniTaskExcel.json?code=${code}&startDate=${startDate}&endDate=${endDate}&userType=${userType}&allChild=0`; 
}

let dataTree = []

const filterData = (d) => {
  const obj = {}
  const children = []
  d.children.forEach(v=>{
    let o = {}
    let c = []
    o.value = v.code
    o.label = v.area
    if (v.children) {
      v.children.forEach(i=>{
        let c_obj = {}
        c_obj.value = i.code
        c_obj.label = i.area
        c.push(c_obj)
      })
    }
    o.children = c
    children.push(o)
  })
  obj.value = d.code
  obj.label = localStorage.getItem("loginPromptName")
  obj.children = children
  dataTree.push(obj)
  // console.log('dataTree: ', dataTree)
}

let num = 0;
const recursion = (obj,k) => {
    num = Math.max(num,k);
    // console.log(k);
    if (obj.children)
        obj.children.forEach(function(v, i){
            recursion(v,k+1);
        });
}

function TopBoard({title}) {
    const userType = localStorage.getItem("userType")
    const loginName = localStorage.getItem("loginName")
    const date7 = dayjs().add(-6, 'day')
    const { dispatch } = useContext(AreaContext)    
    const {area} = useContext(AreaContext)
    // console.log('useContext(AreaContext): ', useContext(AreaContext))
    // console.log('dispatch: ', dispatch)
    const [quName, setQuName] = useState(localStorage.getItem("loginPromptName"))
    const [code, setCode] = useState('')
    const [cols, setCols] = useState(1)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isShowDC, setIsShowDC] = useState(false)
    const [diffDay, setDiffDay] = useState(7)
    const [isShow, setIsShow] = useState(false);
    const [startTime, setStartTime] = useState(date7.format('MM月DD日'))
    const [endTime, setEndTime] = useState(dayjs(now).format('MM月DD日'))
    const [startETime, setStartETime] = useState(date7.format('YYYY-MM-DD'))
    const [endETime, setEndETime] = useState(dayjs(now).format('YYYY-MM-DD'))
    const [upDateTime, setUpDateTime] = useState('无数据')
    const [isShowCalendar, setShowCalendar] = useState(false)
    const [data, setData] = useState({});    
    const [taskName_a, setTaskName_a] = useState(["01", "01-2"])
    const [taskName_b, setTaskName_b] = useState(["01", "01-2"])
    const topBoardData = () => {
      const param_a = {
        date: day,
        areaCode: null,
        streetCode: null,
        neighborhoodCode: null
      }

      // 3是区  1是街道  2是居委
      if (area) {
        param_a.areaCode = null
        param_a.streetCode = null
        param_a.neighborhoodCode = null
        if (area.length >= 6 && area.length <= 7) {
            param_a.areaCode = area
        }
        if (area.length >= 9 && area.length <= 10) {
            // alert(area.length)
            param_a.streetCode = area
        }
        if (area.length >= 12) {
            param_a.neighborhoodCode = area
        }
      }

      const loginName = localStorage.getItem("loginName")

      if (loginName) {            
        if (loginName.length >= 6 && loginName.length <= 7) {
            param_a.areaCode = loginName
        }
        if (loginName.length >= 9 && loginName.length <= 10) {
            // alert(area.length)
            param_a.streetCode = loginName
        }
        if (loginName.length >= 12) {
            param_a.neighborhoodCode = loginName
        }
      }
      
      getTopBoardData(param_a).then(res => {
        // console.log('getTopBoardData-res: ', res)
        if(res) {
          setData(res.data);
          setUpDateTime(dayjs(now).format('YYYY.MM.DD HH:mm'))
        }
      })
    }
    const handleQuName = (code) => {
      let label = ''
      function getLeaf (data) {
        if (data) {
          data.forEach(item => {
            // 街道逻辑修改，全部街道或者居委不选择
            if (item.value === code) {
              label = item.label
            } else {
              if (item.children) {
                let arr = item.children.concat([])
                arr.shift()
                getLeaf(arr)
              }
            }
          })
        }
      }
      getLeaf(dataTree)
      return label
    }
    // 去掉所有全部
    const cData = (data) => {
      let cloneData = JSON.parse(JSON.stringify(data))
      let newData = cloneData.filter(x => x.label.indexOf('全部') === -1)
      newData.forEach(x => x.children && (x.children = cData(x.children)))
      // console.log('newData: ', newData)
      return newData
    }
    useMemo(()=>{
      // alert('useEffect')
      // console.log('useMemo-area: ', area)
      if(area){
        topBoardData()
      }
    },[area]) 
    useEffect(() => {
      // console.log('top-area: ', area)
      // alert('useEffect')
      topBoardData()
      localStorage.removeItem('area')
      const params = {
        code: localStorage.getItem("loginName")
      }
      getAreaList(params).then(res => {
        // console.log('res: ', res)
        filterData(res.data.returnObject)
        // cData(dataTree)
        recursion(dataTree[0], 1)
        setCols(num)
      })
    }, []);
    // console.log('data: ', data)
    return (
        <div className="topboard">
            <p className='topboardtxt'>数据统计截至到 {upDateTime}</p>
            <i className="iconfont iconicon-test" onClick={() => {
                setIsShow(true)
            }}></i>
            {
              isShowDC?<div className="mb">
              <div className="tobBox">
                <div className="tit-header bor-bot">导出条件选择</div>
                <div className="close-box" onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setIsShowDC(false)
                }}><img src={icon_off} alt="" /></div>
                <div className="box-xl bor-bot">
                  <Picker
                    className="tob-picker"
                    data={dataTree}
                    title="选择居委"
                    cascade={true}
                    value={taskName_b}
                    cols={cols}
                    extra='请选择居委'
                    format={(labels) => {
                      return labels.length === 0 ? '请选择居委' : labels.join('-')
                    }}
                    onOk={e => {
                        // console.log('e: ', e)
                        setTaskName_b(e)
                        // console.log('e[e.length-1]:', e[e.length-1])
                        setCode(e[e.length-1])                                         
                        setIsDisabled(false)
                        // console.log('setQuName: ', setQuName(e[e.length-1]))
                        // setQuName(handleQuName(e[e.length-1]))
                        // dispatch({type:UPDATE_AREA,area: e[e.length-1]})
                    }}
                  >
                    <List.Item arrow="down"></List.Item>
                  </Picker>
                </div>

                <div className="box-hr">
                  <span>请选择导出时间范围</span>
                  <List.Item arrow="down"></List.Item>
                </div>
                <div className="calendar" onClick={() => {
                  setShowCalendar(true)
                }}>
                  <div className="dt-box">
                    <span className="dt-txt">{startTime}</span>
                    <span className="dis">开始时间</span>
                  </div>
                  <div className="num-box">
                  <span>{diffDay}天</span>
                  <img src={icon_arrows} alt='' height="7" />
                  </div>
                  <div className="dt-box">
                    <span className="dt-txt">{endTime}</span>
                    <span className="dis">结束时间</span>
                  </div>
                </div>
                {/*  disabled */}
                <Button type="primary" disabled={isDisabled} className="btn"  onClick={() => {
                  // console.log('code: ', code)
                  // console.log('startETime: ', startETime)
                  // console.log('endETime: ', endETime)
                  exportExcel(code, startETime, endETime)
                  setIsShowDC(false)
                }}>生成Excel报表</Button>
                <Calendar
                  visible={isShowCalendar}
                  onCancel={() => {setShowCalendar(false)}}
                  onConfirm={(startTime, endTime) => {
                    // console.log('startTime: ', startTime)
                    const _startTime = dayjs(startTime)
                    const _endTime = dayjs(endTime)
                    // console.log('startTime: ', _startTime.format('MM月DD日') )
                    setStartTime(_startTime.format('MM月DD日'))
                    setStartETime(_startTime.format('YYYY-MM-DD'))
                    // console.log('endTime: ', endTime)
                    // console.log('endTime: ', _endTime.format('MM月DD日') )
                    setEndTime(_endTime.format('MM月DD日'))
                    setEndETime(_endTime.format('YYYY-MM-DD'))
                    setDiffDay(_endTime.diff(_startTime, 'day') + 1)
                    setShowCalendar(false)
                  }}
                  onSelectHasDisableDate={() => {}}
                  getDateExtra={false}
                  defaultDate={new Date(date7)}
                  minDate={new Date(date7)}
                  maxDate={new Date(+now)}
                />
              </div>
            </div>:''
            }
            
            {isShow?<div className="mb" onClick={() => {
                setIsShow(false)
            }}>
                <div className="box">
                  <strong>今日呼叫人数：</strong>
                  <span>今天呼叫的号码数量（单位：人数，已去重） </span>
                  <strong>接通量：</strong>
                  <span>今天接通总数量（已去重） </span>
                  <strong>接通率：</strong>
                  <span>接通率：接通量 / 今日呼叫人数</span>
                  <strong>有感冒相关症状：</strong>
                  <span>反馈有感冒发热等相关症状，需人工跟进 </span>
                  <strong>未接通人数：</strong>
                  <span>今日未接通电话的人数包含关机、停机、空号、无应答、用户忙等 </span>
                  <strong>建议人工跟进：</strong>
                  <span>未明确反馈症状，即已接通量中CDEF意图</span>
                </div>
            </div>:''}
            <div className="leading-out" onClick={() => {
              setIsShowDC(true)
              setIsDisabled(!code)
            }}>导出</div>
            {userType !== '2'?<div className="committee">
                <Picker
                data={dataTree}
                title="选择街道居委"
                cascade={true}
                value={taskName_a}
                cols={cols}
                format={(labels) => {
                  return labels.length === 0 ? '请选择需要看的街道居委报告' : labels.join('-')
                }}
                onOk={e => {
                  console.log([...new Set(e)], dataTree);
                  setTaskName_a([...new Set(e)])
                  setTaskName_b([...new Set(e)])
                  setCode([...new Set(e)][[...new Set(e)].length-1])
                  setQuName(handleQuName([...new Set(e)][[...new Set(e)].length-1]))
                  dispatch({type:UPDATE_AREA,area: e[e.length-1]})
                }}
                >
                <List.Item arrow="down"></List.Item>
                </Picker>
              </div>:''
            }
            
              <div className="top-title">{quName}健康访谈分析报告</div>
            <div className="top-row">
                {data.callPersons && <Board {...data.callPersons} />}
                {data.callSucess && <Board {...data.callSucess} />}
                {data.callRate && <Board {...data.callRate} />}
            </div>
            <div className="top-row">
                {data.callNum && <Board {...data.callNum} color="#FF3C26" />}
                {data.callFail && <Board {...data.callFail} color="#FFA02C" />}
                {data.followUp && <Board {...data.followUp} color="#FFA333" />}
            </div>
        </div>
    )
}
export default TopBoard