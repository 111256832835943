import React, { useState, Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { NavBar, Card, Toast, Picker } from 'antd-mobile';
import { Table, Tag, Icon } from 'antd'
import dayjs from 'dayjs'
// queryCalledPepoleDetail
import { queryCalledPepoleDetail } from '../api'
import './boxDet.css'

import filterImg from '../images/icon_filter.png'

const IntentionLevel = [{ text: '全部', value: 0 }, { text: 'A', value: 1 },
{ text: 'B', value: 2 }, { text: 'C', value: 3 }, { text: 'D', value: 4 },
{ text: 'E', value: 5 }, { text: 'F', value: 6 }]





// const data = [];

function DoxDet() {
  const columns = [0,
    [{
      title: '姓名',
      dataIndex: 'customerName',
      // fixed: 'left',
      width: 120,
      render: text => <div>{text}</div>,
    },
    {
      title: '电话号码',
      dataIndex: 'phone',
      key: 'phone',
      width: 140,
      render(val) {
          return (
              <Fragment>
                  <span>{phoneVisible ? val : (val.substr(0, 3) + '****' + val.substr(8, 4))}&nbsp;&nbsp;</span>
              </Fragment>
          )
      }
    },
    {
      title: '外呼时间',
      dataIndex: 'createTime',
      width: 120,
      render(val) {
          return (
              <Fragment>
                  <span>{dayjs(val).format('HH:mm:ss')}&nbsp;&nbsp;</span>
              </Fragment>
          )
      }
    },
    {
      title: '通话结果',
      width: 120,
      dataIndex: 'connectedResultType',      
      render(val) {
        return (
            <Fragment>
                <span>{ConnectedResultTypeMap[val]}&nbsp;&nbsp;</span>
            </Fragment>
        )
      }
    },
    {
      title: '意向等级',
      width: 106,
      dataIndex: 'intentionLevel',
      render(val) {
          if (val === undefined) return '-'
          return String.fromCharCode('A'.charCodeAt(0) + val - 1)
      }
    },
    {
      title: '居委',
      width: 160,
      dataIndex: 'area5',
    },
    {
      title: '操作',
      width: 120,
      dataIndex: 'id',
      render(val, row) {
        return <div style={{ color: '#00A0FF', textDecoration: 'underline' }} onClick={() => {
          // console.log('val: ', val)
          // console.log('row: ', row)
          // return
          window.sessionStorage.setItem('row', JSON.stringify(row))
          window.location.href = `/task/${val}`
        }}>详情</div>
      }
    },
    ],[
      {
        title: '姓名',
        dataIndex: 'customerName',
        // fixed: 'left',
        width: 120,
        render: text => <div>{text}</div>,
      },
      {
        title: '电话号码',
        dataIndex: 'phone',
        key: 'phone',
        width: 140,
        render(val) {
            return (
                <Fragment>
                    <span>{phoneVisible ? val : (val.substr(0, 3) + '****' + val.substr(8, 4))}&nbsp;&nbsp;</span>
                </Fragment>
            )
        }
      },
      {
        title: '外呼时间',
        dataIndex: 'createTime',
        width: 120,
        render(val) {
            return (
                <Fragment>
                    <span>{dayjs(val).format('HH:mm:ss')}&nbsp;&nbsp;</span>
                </Fragment>
            )
        }
      },
      {
        title: '通话结果',
        width: 120,
        dataIndex: 'connectedResultType',      
        render(val) {
          return (
              <Fragment>
                  <span>{ConnectedResultTypeMap[val]}&nbsp;&nbsp;</span>
              </Fragment>
          )
        }
      },
      {
        title: '意向等级',
        filters: IntentionLevel,
        filterMultiple: false,
        onFilter: (value, record) => { 
          // console.log('onFilter: v: ', typeof value); 
          // console.log('onFilter: record.connectedResultType: ', typeof record.connectedResultType)         
          // console.log('onFilter: record: ', record.connectedResultType == value) 
          return record.intentionLevel * 1 === value * 1
        },  
        width: 106,
        dataIndex: 'intentionLevel',
        render(val) {
            if (val === undefined) return '-'
            return String.fromCharCode('A'.charCodeAt(0) + val - 1)
        }
      },
      {
        title: '居委',
        width: 160,
        dataIndex: 'area5',
      },
      {
        title: '操作',
        width: 120,
        dataIndex: 'id',
        render(val, row) {
          return <div style={{ color: '#00A0FF', textDecoration: 'underline' }} onClick={() => {
            // console.log('val: ', val)
            // console.log('row: ', row)
            // return
            window.sessionStorage.setItem('row', JSON.stringify(row))
            window.location.href = `/task/${val}`
          }}>详情</div>
        }
      },
    ],[{
      title: '姓名',
      dataIndex: 'customerName',
      // fixed: 'left',
      width: 120,
      render: text => <div>{text}</div>,
    },
    {
      title: '电话号码',
      dataIndex: 'phone',
      key: 'phone',
      width: 140,
      render(val) {
          return (
              <Fragment>
                  <span>{phoneVisible ? val : (val.substr(0, 3) + '****' + val.substr(8, 4))}&nbsp;&nbsp;</span>
              </Fragment>
          )
      }
    },
    {
      title: '外呼时间',
      dataIndex: 'createTime',
      width: 120,
      render(val) {
          return (
              <Fragment>
                  <span>{dayjs(val).format('HH:mm:ss')}&nbsp;&nbsp;</span>
              </Fragment>
          )
      }
    },
    {
      title: '通话结果',
      width: 106,
      dataIndex: 'connectedResultType',
      filters: [
        {
          text: '关机',
          value: '2',
        },
        {
          text: '空号',
          value: '6',
        },
        {
          text: '停机',
          value: '1',
        },
        {
          text: '无应答',
          value: '4',
        },
        {
          text: '用户忙',
          value: '13',
        }
      ],
      onFilter: (value, record) => { 
        // console.log('onFilter: v: ', typeof value); 
        // console.log('onFilter: record.connectedResultType: ', typeof record.connectedResultType)         
        // console.log('onFilter: record: ', record.connectedResultType == value) 
        return record.connectedResultType * 1 === value * 1
      },      
      render(val) {
        return (
            <Fragment>
                <span>{ConnectedResultTypeMap[val]}&nbsp;&nbsp;</span>
            </Fragment>
        )
      }
    },
    {
      title: '意向等级',
      filters: IntentionLevel,
      filterMultiple: false,
      onFilter: (value, record) => { 
        // console.log('onFilter: v: ', typeof value); 
        // console.log('onFilter: record.connectedResultType: ', typeof record.connectedResultType)         
        // console.log('onFilter: record: ', record.connectedResultType == value) 
        return record.intentionLevel * 1 === value * 1
      },  
      width: 106,
      dataIndex: 'intentionLevel',
      render(val) {
          if (val === undefined) return '-'
          return String.fromCharCode('A'.charCodeAt(0) + val - 1)
      }
    },
    {
      title: '居委',
      width: 160,
      dataIndex: 'area5',
    },
    {
      title: '操作',
      width: 120,
      dataIndex: 'id',
      render(val, row) {
        return <div style={{ color: '#00A0FF', textDecoration: 'underline' }} onClick={() => {
          // console.log('val: ', val)
          // console.log('row: ', row)
          // return
          window.sessionStorage.setItem('row', JSON.stringify(row))
          window.location.href = `/task/${val}`
        }}>详情</div>
      }
    },
    ]
  ];
  const day = dayjs().format('YYYY-MM-DD')
  const pageSize = 10
  let { id, area } = useParams();
  // console.log('id: ', id)  
  console.log('area: ', area)
  const [phoneVisible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const [columnsData, setColumns] = useState(columns)
  const iconType = phoneVisible ? 'eye' : 'eye-invisible' 
  const [pagination, setPage] = useState({  // 分页数据
    total: 0,
    current: 1,
    pageSize
  })  
  const [intention, setIntention] = useState([])
  const [resultType, setResult] = useState([])
  const titleArr = ['','有感冒相关症状通数详情','建议人工跟进通数详情','未接通跟进数详情']  
//getQueryCalledPepoleDetail 获取详情数据
const getQueryCalledPepoleDetail = (id, page) => {
  // setTitle(titleArr[id])
  
  const param_a = {
    areaCode: null,
    streetCode: null,
    neighborhoodCode: null
  }

  const area = localStorage.getItem("area")
  // 3是区  1是街道  2是居委
  if (area) {
      param_a.areaCode = null
      param_a.streetCode = null
      param_a.neighborhoodCode = null
      if (area.length >= 6 && area.length <= 7) {
          param_a.areaCode = area
      }
      if (area.length >= 9 && area.length <= 10) {
          // alert(area.length)
          param_a.streetCode = area
      }
      if (area.length >= 12) {
          param_a.neighborhoodCode = area
      }
  }

  const loginName = localStorage.getItem("loginName")

  if (loginName && !area) {      
      // alert(loginName)      
    if (loginName.length >= 6 && loginName.length <= 7) {
      // alert(loginName) 
        param_a.areaCode = loginName
    }
    if (loginName.length >= 9 && loginName.length <= 10) {
        // alert(area.length)
        param_a.streetCode = loginName
    }
    if (loginName.length >= 12) {
        param_a.neighborhoodCode = loginName
    }
  }

  const params = {
    ...param_a,
    date: day,
    // date: "2020-02-21",
    currentPage:page || 1,
    pageSize,
    intentType: id
  }
  // console.log('param_a: ', param_a)
  queryCalledPepoleDetail(params).then((res) => {
    // console.log('res: ', res)
    // console.log('res-returnObject: ', res.data.returnObject.detailList)
    if (res.data.returnObject){
      setData(res.data.returnObject.detailList)
      // console.log('data: ', data)
      setPage({          
        current: page || 1,
        pageSize,
        total: res.data.returnObject.totalNumber
      })
    }
  })
}
// 意向筛选
function onIntentionChange(list) {
  // console.log('onIntentionChange: ', list)
  setIntention(list)
  // console.log('intention: ', intention)
  setResult([])
  setPage({
      ...pagination,
      current: 1,
      total: 0
  })
  queryCalledPepoleDetail({
    date: day,
    currentPage: pagination.current,
    pageSize,
    intentType: id,
    intentionLevelList: list[0] === 0 ? [] : list[0]
  }).then(res => {
      setData(res.data.returnObject.detailList)
      setPage({
          ...pagination,
          total: res.totalNumber
      })
  })
}
  useEffect(() => {
    getQueryCalledPepoleDetail(id)
  }, [id])
  const ConnectedResultTypeMap = {
    1: '接听',
    2: '关机',
    11: '线路故障',
    4: '无应答',
    13: '用户忙',
    6: '空号',
    7: '呼叫转移',
    8: '呼叫失败',
    14: '停机',
    16: '来电提醒'
}
  function onPageChange(pagination) {
    // console.log('onPageChange - pagination: ', pagination)
    getQueryCalledPepoleDetail(id, pagination.current)
    return
    // const params = {
    //   date: day,
    //   // date: "2020-02-21",
    //   currentPage:pagination.current,
    //   pageSize,
    //   intentType: id
    // }
    // queryCalledPepoleDetail(params).then(res => {
    //   setData(res.data.returnObject.detailList)
    //   setPage({          
    //     current: pagination.current,
    //     pageSize,
    //     total: res.data.returnObject.totalNumber
    //   })
    // })
  }
  return (
    <div>
      <NavBar
      mode="light"
      icon={<Icon type="left" />}
      leftContent="返回"
      onLeftClick={() => {window.history.back(-1)}}
      />            
      <Card full className="card">
        <Card.Body>
          <div className="tit">{titleArr[id]}</div>
          <Icon className="icon" type={iconType} onClick={() => { setVisible(!phoneVisible) }} />
          <Table 
            columns={columns[id]} 
            dataSource={data}
            scroll={{ x: 720 }}
            pagination={pagination}
            onChange={onPageChange}
            rowKey={(record, index) => index}
            locale={{ emptyText: '暂无数据' }} 
          />
        </Card.Body>
      </Card>
    </div>
  )
}

export default DoxDet;