import React, { useState, useEffect, useContext, useMemo } from 'react';
import {AreaContext,UPDATE_AREA} from '../selectArea'

import { Radio } from 'antd';
import dayjs from 'dayjs'

import { getIntentionData } from '../api'

import ChartBoard from './ChartBoard'

import './intention.css'
import 'antd/es/radio/style/css'





function Intention() {
    const userType = localStorage.getItem("userType")
    const loginName = localStorage.getItem("loginName")
    const {area} = useContext(AreaContext)
    // console.log('intention-area: ', area)
    const [isShow, setIsShow] = useState(false);
    const [type, setType] = useState(1);
    const [data, setData] = useState({
        time: [],
        data: []
    });
    const intentionData = () => {        
        const date = type === 1? dayjs() : dayjs().add(-1, 'day')
        const param_a = {
            date: date.format('YYYY-MM-DD'),
            areaCode: null,
            streetCode: null,
            neighborhoodCode: null
        }
        
        // 3是区  1是街道  2是居委
        if (area) {
            param_a.areaCode = null
            param_a.streetCode = null
            param_a.neighborhoodCode = null
            if (area.length >= 6 && area.length <= 7) {
                param_a.areaCode = area
            }
            if (area.length >= 9 && area.length <= 10) {
                // alert(area.length)
                param_a.streetCode = area
            }
            if (area.length >= 12) {
                param_a.neighborhoodCode = area
            }
        }

        const loginName = localStorage.getItem("loginName")
  
        if (loginName) {      
            // alert(loginName)      
          if (loginName.length >= 6 && loginName.length <= 7) {
            // alert(loginName) 
              param_a.areaCode = loginName
          }
          if (loginName.length >= 9 && loginName.length <= 10) {
              // alert(area.length)
              param_a.streetCode = loginName
          }
          if (loginName.length >= 12) {
              param_a.neighborhoodCode = loginName
          }
        }
        // console.log('param_a: ', param_a)
        
        getIntentionData(param_a).then(res => {
            setData(res.data)
        })
    }
    useMemo(()=>{
        // console.log('useMemo-area: ', area)
        if(area) {
            setType(1)
            intentionData()
        }
      },[area]) 
    useEffect(() => {
        // console.log('intention-area: ',area)
        intentionData()
    }, [type]);

    return (
        <div className="intention">
            <i className="iconfont iconicon-test intention-icon" onClick={() => {
                setIsShow(true)
            }}></i>
            {isShow?<div className="mb" onClick={() => {
                setIsShow(false)
            }}>
                <div className="box">
                意图，是指接听用户在和机器人对话，直至挂机时，
所表达出的最终意图
分成A-F级别6个级别来区分。 根据不同意图级别来判断下一步人工该如何沟通。在 防疫场景下，B类为无需跟进的唯一意图。</div>
            </div>:''}
            <div className="intention-header">
        <span>意图分类级别</span>
                <Radio.Group size="small" onChange={e => setType(e.target.value)} value={type}>
                    <Radio.Button value={1}>今天</Radio.Button>
                    <Radio.Button value={2}>昨天</Radio.Button>
                </Radio.Group>
            </div>
            <ChartBoard data={data} />
            {/* <span>{type}</span> */}
        </div>
    )
}
export default Intention