import axios from 'axios'
import dayjs from 'dayjs'

// function getUrlParam(paramName) {
//     let q = {};
//     window.location.search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => q[k] = v);
//     return q[paramName] || '';
// }


// const extra = { "speechcraftId": getUrlParam('id') || "8f6aaec997204378a03928545530dcf2" }
// const day = '2020-02-10'
const day = dayjs().format('YYYY-MM-DD')

// function mockData(data) {
//     return new Promise((resolve) => {
//         resolve(data)
//     })
// }

function formatIntentionData(callData) {
    let time = [], data = [[], [], [], [], [], []]
    for (let i = 9; i < 23; i++) {
        time.push((i > 9 ? i : '0' + i) + ':00')
        let temp = callData[i] || callData['0' + i] || {}

        data[0].push(temp.a || 0)
        data[1].push(temp.b || 0)
        data[2].push(temp.c || 0)
        data[3].push(temp.d || 0)
        data[4].push(temp.e || 0)
        data[5].push(temp.f || 0)

    }
    return {
        data: { time, data }
    }

}
// console.log('localStorage.getItem("token"): ', localStorage.getItem("token"))

// const headers = {
//     'Content-Type': 'application/json',
//     'Authorization': localStorage.getItem("token")
// }

// console.log('headers: ' ,headers)



function checkStatus(obj) {
    // console.log('checkStatus: ', obj)
    const url = window.location.pathname;
    // console.log('url: ', url)
    if(obj.resultMessageEnum === '000104') {
        window.history.back(-1); 
        return;
    }
}

export const getTopBoardData = (params) => {
    let url = 'queryTaskTotalInfoByArea'
    return axios.post(`/services/miniProgram/${url}.json`, 
    params, 
    { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        if (res.data.resultMessageEnum === '0000'){
            // alert('getTopBoardData-api')
            // console.log('api-res: ', res)
            checkStatus(res)
            // debugger
            let data = res.data.returnObject
            let todayCallRate = Math.round(100 * data[0].connectedRate)
            let yestdayCallRate = Math.round(100 * data[1].connectedRate)
            return {
                data: {
                    callNum: {
                        title: data[0].calledNumber + '人',
                        label: '有感冒相关症状',
                        num: data[0].calledNumber - data[1].calledNumber
                    },
                    callSucess: {
                        title: data[0].connectedNumber + '人',
                        label: '接通量',
                        num: data[0].connectedNumber - data[1].connectedNumber
                    },
                    callRate: {
                        title: todayCallRate + '%',
                        label: '接通率',
                        num: todayCallRate - yestdayCallRate + '%'
                    },
                    callPersons: {
                        title: data[0].calledPeopleNumber + '人',
                        label: '今日呼叫人数',
                        num: data[0].calledPeopleNumber - data[1].calledPeopleNumber
                    },
                    callFail: {
                        title: data[0].noAnswerNumber + '人',
                        label: '未接通人数',
                        num: data[0].noAnswerNumber - data[1].noAnswerNumber
                    },
                    followUp: {
                        title: data[0].noAnswerPeopleNumber + '人',
                        label: '建议人工跟进',
                        num: data[0].noAnswerPeopleNumber - data[1].noAnswerPeopleNumber
                    }
                }
            }
        }
    })
}

export const getIntentionData = (date) => {
    let url = ''
    if(localStorage.getItem("userType")) {
        url = 'queryTaskIntentionByArea'
    } else {
        url = 'queryTaskIntentionByTaskId'
    }
    return axios.post(`/services/miniProgram/${url}.json`,
    date, 
    { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        return formatIntentionData(res.data.returnObject)
    })
}

export const getTaskList = () => {
    return axios.post('/services/miniProgram/queryTaskListByspeechcraftId.json', {
    }, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        return {
            data: res.data.returnObject.map(t => ({
                id: t.id,
                name: t.taskName
            }))
        }
    })
}

export const queryTaskListBytaskId = (params) => {
    return axios.post('/services/miniProgram/queryTaskListBytaskId.json', {
        ...params
    }, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        return res.data.returnObject
    })
}

// /services/miniProgram/queryTaskItemListByArea.json
export const queryTaskItemListByArea = (params) => {
    return axios.post('/services/miniProgram/queryTaskItemListByArea.json', params, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        return res.data.returnObject
    })
}

export const getTaskDetail = (params) => {
    return axios.post('/services/miniProgram/queryTaskResultRecognition.json', params, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } })
        .then(res => {
            checkStatus(res)
            return res.data.returnObject
        })
}
export const getTaskDetail2 = (params) => {
    return axios.post('/services/miniProgram/queryTaskItemDetail.json', params, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } })
        .then(res => {
            checkStatus(res)
            return res.data.returnObject
        })
}

export const saveRemark = (params) => {
    return axios.post('/services/miniProgram/addTaskItemReamrk.json', params, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        return res.data
    })
}

export const queryRemarks = (params) => {
    return axios.post('/services/miniProgram/queryTaskItemReamrk.json', params, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        return res.data.returnObject
    })
}

export const loginData = (params) => {
    return axios.post('/services/miniProgram/loginByAccountName.json', params).then(res => {
        return res.data
    })
}

export const auth = (params) => {
    return axios.get('/services/miniProgram/login.json', params).then(res => {
        return res.data
    })
}

// queryTaskListByArea.json
export const getHZTaskList = (params) => {
    return axios.post('/services/miniProgram/queryArea5List.json', {
    }, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } }).then(res => {
        checkStatus(res)
        if (res.data.returnObject) {
            return {            
                data: res.data.returnObject.map(t => ({
                    id: t.areaCode,
                    name: t.area
                }))
            }
        } else {
            return {
                data: []
            }
        }
    })
}

// 街道居委接口
export const getAreaList = (params) => {
    return axios.get('/services/miniProgram/getAreaChilds.json', {
        params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token")
        }
    })
}

// 导出exsl /services/miniProgram/exportMiniTaskExcel.json
export const exportMiniTaskExcel = (params) => {
    return axios.get('/services/miniProgram/exportMiniTaskExcel.json', { 
        params,
        headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
        // 'Authorization': '46925224d61a473590855b80704e78c2'
    } })
}


// /services/miniProgram/queryCalledPepoleDetail.json
export const queryCalledPepoleDetail = (params) => {
    return axios.post('/services/miniProgram/queryCalledPepoleDetail.json', params, { headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    } })
}