import React from 'react'

function Content({ data }) {
    const list = JSON.parse(data.recognitionContent || '[]')
    console.log(list)
    return (
        <div className="task-customer">
            <div className="task-header">
                <span>识别内容</span>
            </div >
            <ul className="task-talk">
                {list.map((l, index) => {
                    return (<li key={index}>
                        <i className={l.role}></i>
                        <span className={'talk_c talk_' + l.role}>{l.text}</span>
                    </li>)
                })}
            </ul>
        </div>
    )
}
export default Content