import React from 'react';

import ChartLegend from './ChartLegend'
import Echart from './Echart'

import ColorList from './ColorList'

// const TagStr = ['：受访人员反馈有感冒相关症状，需人工复核', '：受访人员反馈无感冒相关症状   ',
//     '：不配合调研或需要确认自身情况   ', '：摸排未完成，需人工复核   ',
//     '：反馈不是本人   ', '：开场白受访者直接挂断   ']

// function createLineOption(time, data) {
//     let letterCode = 'A'.charCodeAt(0)
//     let series = data.map((d, index) => {
//         return {
//             name: String.fromCharCode(letterCode + index) + TagStr[index],
//             type: 'line',
//             smooth: true,
//             data: d
//         }
//     })
//     return {
//         color: ColorList,
//         legend: {
//             show: false,
//         },
//         grid: {
//             top: 30,
//         },
//         tooltip: {
//             trigger: 'axis',
//             position: [10, 10]
//         },
//         xAxis: {
//             type: 'category',
//             boundaryGap: false,
//             data: time,
//             axisLabel: {
//                 interval: 0,
//                 rotate: 45
//             },
//         },
//         yAxis: {
//             name: '通话数量（通）',
//             type: 'value',
//             minInterval: 1,
//             nameTextStyle: {
//                 align: 'left'
//             }
//         },
//         series
//     };

// }

function createPieOption(data) {
    let letterCode = 'A'.charCodeAt(0)
    let seriesData = data.map((d, index) => {
        return {
            name: String.fromCharCode(letterCode + index),
            value: d.reduce((a, b) => (a + b), 0)
        }
    })
    return {
        color: ColorList,
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
        },

        series: [
            {
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                data: seriesData,
                minAngle: 1,
                label: {
                    formatter: '{a|{b}}\n{hr|}\n{d}% ',
                    rich: {
                        a: {
                            align: 'center',
                            width: 30,
                        },
                        hr: {
                            width: 30,
                            borderWidth: 0.5,
                            height: 0
                        }
                    }
                }
            }
        ]
    }
}

function ChartBoard({ data }) {
    // const { time, data: lengendData } = data
    const { data: lengendData } = data

    // let lineOption = createLineOption(time, lengendData)
    let pieOption = createPieOption(lengendData)
    return (
        <div className="intention-chart">
            <ChartLegend data={lengendData} />
            {/* <Echart style={{ height: 200 }} option={lineOption} /> */}
            <Echart style={{ height: 200 }} option={pieOption} />
        </div>
    )
}
export default ChartBoard