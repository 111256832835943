import React from 'react';
import TopBoard from '../TopBoard'
import Intention from '../intention/Intention'
import OutTask from '../outTask/OutTask'
import { Area } from '../selectArea'

function Detailed() {
  const title = localStorage.getItem("loginPromptName")
  return (
    <div className="detailed-page">
      <Area>
        <TopBoard title={title}/>
        <Intention />
        <OutTask type="HZ" />      
      </Area>
    </div>
  )
}

export default Detailed
