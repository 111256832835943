import React from 'react'

function CallDetail({ data }) {
    return (
        <div className="task-customer">
            <div className="task-header">
                <span>通话情况</span>
            </div >
            <ul className="task-list">
                <li>拨打时间：{data.callTime}</li>
                <li>通话时长：{data.connectedDuration}秒</li>
                <li>对话轮数：{data.interactTimes}轮</li>
                <li>知识库触发量：{data.krcCount}个</li>
                <li>不理解次数：{data.intentUncertainCount}次</li>
                <li>挂断情况：{data.hangUpSituation === 1 ? '用户' : '机器人'}</li>
                <li>挂断节点：{data.hangUpNode}</li>
            </ul>
        </div>
    )
}
export default CallDetail