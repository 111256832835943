import React from 'react'

function Recoard({data}) {
    return (
        <div className="task-customer">
            <div className="task-header">
                <span>对话录音</span>
            </div >
            <div className="task-row">
                <audio controls src={data.soundPath}></audio>
            </div>
        </div>
    )
}
export default Recoard