import React, { Component } from 'react';
import echarts from 'echarts'

class Main extends Component {
    componentDidMount() {
        this.chart = echarts.init(this.el);
    }
    componentWillReceiveProps(newProps) {
        if (newProps.option !== this.props.option) {
            this.chart.setOption(newProps.option);
        }
    }
    render() {
        return (
            <div {...this.props} ref={e => this.el = e}>

            </div>
        );
    }
}
export default Main