import React from 'react';

import TopBoard from './TopBoard'
import Intention from './intention/Intention'
import OutTask from './outTask/OutTask'
import {Redirect} from 'react-router-dom'
import { Area } from './selectArea'

import './home.css'

function Home() {
    if(!localStorage.getItem("token")) {
        return <Redirect to='/login'/>
    }

    return (
        <div className="home">
            <Area>
                <TopBoard />
                <Intention />
                <OutTask />
            </Area>          
        </div>
    )
}
export default Home