import React, { useState, useEffect } from 'react';

import { Picker, List } from 'antd-mobile'
import { Icon } from 'antd'

import TaskTable from './TaskTable'

import { getTaskList, getHZTaskList } from '../api'

import './outtask.css'
import 'antd/es/icon/style/css'


function Task({type}) {
    const [taskList, setList] = useState([]);
    const [taskId, setTaskId] = useState(null);
    const [taskName, setTaskName] = useState('外呼任务')
    const [phoneVisible, setVisible] = useState(false)
    const iconType = phoneVisible ? 'eye' : 'eye-invisible'    
    const userType = localStorage.getItem("userType")
    useEffect(() => {
        // alert(type)
        // console.log('type: ', type)
        if (type === 'HZ') {
            if(userType === '1') {
                getHZTaskList().then(res => {
                    // console.log('getHZTaskList-res: ', res)                
                    if (res.data.length > 0) {
                        setList(res.data.map(t => ({
                            value: t.id,
                            label: t.name
                        })))
                        setTaskName(res.data[0].name)
                        setTaskId(res.data[0].id)
                    }
                })
            }
        } else {
            getTaskList().then(res => {
                setList(res.data.map(t => ({
                    value: t.id,
                    label: t.name
                })))
                if (res.data.length > 0) {
                    setTaskName(res.data[0].name)
                    setTaskId(res.data[0].id)
                }
            })
        }
    }, [type, userType]);
    // console.log('userType: ', typeof userType)
    return (
        <div className="task">
            {/* <div className="task-header">                    
                <span>
                    {taskName}
                    <Picker
                        extra=''
                        data={taskList}
                        title={type === 'HZ'?'居委名称':'任务名称'}
                        cols={1}
                        onOk={e => {
                            const task = taskList.find(t => t.value === e[0])
                            console.log('任务名称: ', task)
                            setTaskId(e[0])
                            setTaskName(task.label)
                        }}
                    >
                        <List.Item arrow="down" style={{ paddingLeft: 0 }}></List.Item>
                    </Picker>
                </span>
                <Icon type={iconType} onClick={() => { setVisible(!phoneVisible) }} />
            </div > */}
                
            <TaskTable taskId={taskId} phoneVisible={phoneVisible} type={type} />
        </div >
    )
}
export default Task