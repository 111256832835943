import React from 'react';

import ColorList from './ColorList'
const strArr = [
    '反馈有感冒相关症状，需人工跟进',
    '反馈无感冒相关症状',
    '不明确情况或不配合，建议人工跟进',
    '摸排未完成，建议人工跟进',
    '反馈不是本人，建议人工跟进',
    '接通后直接挂断，建议人工跟进'
]

const txtColor = [
    '#FF2015',
    '#333333',
    '#FF9514',
    '#FF9514',
    '#FF9514',
    '#FF9514'
]

function LegendItem({ data, index }) {

    return (
        <div className="legend-item">
            <span className="legend-letter" style={{ background: ColorList[index] }}>{data.letter}</span>
            <span className="legend-txt" style={{ color: txtColor[index] }}>{strArr[index]}</span>
            <span className="legend-total" style={{ color: txtColor[index] }}>{data.total}通</span>
        </div>
    )
}

function ChartLegend({ data = [] }) {
    let letterCode = 'A'.charCodeAt(0)
    let legendData = []
    data.forEach((d, index) => {
        legendData.push({
            letter: String.fromCharCode(letterCode + index),
            total: d.reduce((a, b) => (a + b), 0)
        })
    })
    return (
        <div className="intention-chart-legend">
            {legendData.map((d, index) => {
                return <LegendItem key={index} data={d} index={index} />
            })}
        </div>
    )
}
export default ChartLegend