import React, { useReducer } from 'react';
import ShowArea from './showArea';
import Buttons from './Buttons';
import { Color } from './color';   //引入Color组件

function Example6(){
    alert('kv')
    return (
        <div>
            <Color>
                <ShowArea />
                <Buttons />
            </Color>

        </div>
    )
}

export default Example6