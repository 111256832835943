import React, { useState, useEffect } from 'react';
import './index.css'
import qs from 'query-string';
import { Toast } from 'antd-mobile'
import {Redirect} from 'react-router-dom'

import failed from '../images/failed@2x.png'

import { auth } from '../api'

  const url = window.location.search;
  console.log('qs: ', qs)
  const urlObj = qs.parse(url);
  console.log('urlObj: ', urlObj)

function Govern() {
  const [status, setStatus] = useState('')
  useEffect(() => {
    if(urlObj.at) {
      auth({params: {
        at: urlObj.at
      } }).then(res => {
          console.log('res: ', res)
          if(res.resultMessageEnum === '0000') {
            Toast.success('登陆成功！', 1)
            // alert('userType: ' + res.returnObject.userType)
            localStorage.setItem("token", res.returnObject.token)
            localStorage.setItem("loginPromptName", res.returnObject.loginPromptName)
            localStorage.setItem("loginName", res.returnObject.loginName)
            localStorage.setItem("userType", res.returnObject.userType)
            setStatus('success')
          } else {
            setStatus('err')
          }
      })
    } else {
      Toast.success('参数缺失！', 1)
    }
  }, [])

  if(status === '') {
    return (
      <div className='wait-msg'>页面跳转中，请等待。。。</div>
    )
  } else if (status === 'err') {
    return (
      <div className='err-box'>
        <img src={failed} alt='' className='err-box-img' />
        <span className='err-msg'>授权已失效！请联系授权方激活</span>
      </div>
    )
  } else if (status === 'success') {
    return <Redirect to='/detailed'/>
  }
}

export default Govern