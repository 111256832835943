import React, { useState, useEffect, Fragment, useContext, useMemo } from 'react';
import {AreaContext,UPDATE_AREA} from '../selectArea'

import { Table, Icon } from 'antd'
import { Toast, Picker } from 'antd-mobile'
import dayjs from 'dayjs'

import filterImg from '../images/icon_filter.png'

import { queryTaskListBytaskId, queryTaskItemListByArea } from '../api'

import 'antd/es/table/style/css'
import 'antd/es/icon/style/css'

const now = new Date();
const day = dayjs().format('YYYY-MM-DD')

const ConnectedResultTypeMap = {
    1: '接听',
    2: '关机',
    11: '线路故障',
    4: '无应答',
    13: '用户忙',
    6: '空号',
    7: '呼叫转移',
    8: '呼叫失败',
    14: '停机',
    16: '来电提醒'
}
const ResultTypePairs = Object.keys(ConnectedResultTypeMap).map(k => {
    // console.log('ResultTypePairs: ')
    return {
        label: ConnectedResultTypeMap[k],
        value: k
    }
})
ResultTypePairs.unshift({ label: '全部', value: 0 })
const IntentionLevel = [{ label: '全部', value: 0 }, { label: 'A', value: 1 },
{ label: 'B', value: 2 }, { label: 'C', value: 3 }, { label: 'D', value: 4 },
{ label: 'E', value: 5 }, { label: 'F', value: 6 }]

function copyTxt(text) {
    if (typeof document.execCommand !== "function") {
        alert("复制失败，请长按复制");
        return;
    }
    var dom = document.createElement("textarea");
    dom.value = text;
    dom.setAttribute('style', 'display: block;width: 1px;height: 1px;');
    document.body.appendChild(dom);
    dom.select();
    var result = document.execCommand('copy');
    document.body.removeChild(dom);
    if (result) {
        Toast.success('号码已复制！', 1);
        return;
    }
    if (typeof document.createRange !== "function") {
        Toast.info("复制失败，请长按复制", 1);
        return;
    }
    var range = document.createRange();
    var div = document.createElement('div');
    div.innerHTML = text;
    div.setAttribute('style', 'height: 1px;fontSize: 1px;overflow: hidden;');
    document.body.appendChild(div);
    range.selectNode(div);
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        selection.removeAllRanges();
    }
    selection.addRange(range);
    document.execCommand('copy');
    Toast.success('号码已复制！', 1);
}

function TaskTable({ taskId, phoneVisible, type, history }) {
    
    const userType = localStorage.getItem("userType")
    const loginName = localStorage.getItem("loginName")
    // console.log('taskId: ', taskId)
    const {area} = useContext(AreaContext)
    const pageSize = 10
    const [data, setData] = useState([]);
    const [pagination, setPage] = useState({
        total: 0,
        current: 1,
        pageSize
    })
    const [resultType, setResult] = useState([])
    const [intention, setIntention] = useState([])

    const taskItemListByArea = (page) => { 
        // console.log('pagination: ', pagination)        
        const param_a = {
            date: day,
            currentPage: page?page-1 : pagination.current - 1,
            pageSize,
            areaCode: null,
            streetCode: null,
            neighborhoodCode: null
        }
        
        // 3是区  1是街道  2是居委
        if (area) {
            param_a.areaCode = null
            param_a.streetCode = null
            param_a.neighborhoodCode = null
            if (area.length >= 6 && area.length <= 7) {
                param_a.areaCode = area
            }
            if (area.length >= 9 && area.length <= 10) {
                // alert(area.length)
                param_a.streetCode = area
            }
            if (area.length >= 12) {
                param_a.neighborhoodCode = area
            }
        }

        const loginName = localStorage.getItem("loginName")
  
        if (loginName) {            
          if (loginName.length >= 6 && loginName.length <= 7) {
              param_a.areaCode = loginName
          }
          if (loginName.length >= 9 && loginName.length <= 10) {
              // alert(area.length)
              param_a.streetCode = loginName
          }
          if (loginName.length >= 12) {
              param_a.neighborhoodCode = loginName
          }
        }
        
        // console.log('param_a: ', param_a)
        queryTaskItemListByArea(param_a).then(res => {
            // setData(res.recordList)
            if (res.recordList) {
                setData(res.recordList)
                setPage({
                    current: page || 1,
                    pageSize ,
                    total: res.totalNumber
                })
            }
        })
    }

    
    useMemo(()=>{
    // console.log('useMemo-area: ', area)
      if(area) {taskItemListByArea()}
    
    },[area]) 

    useEffect(() => {
        setResult([])
        setIntention([])
        taskItemListByArea()
        // pagination.current = 1
        // if (type === 'HZ') {
        //     queryTaskItemListByArea({
        //         currentPage: pagination.current - 1,
        //         pageSize,
        //         areaCode: taskId,
        //         connectedResultTypeList: resultType[0] === 0 ? [] : resultType,
        //         intentionLevelList: intention[0] === 0 ? [] : intention
        //     }).then(res => {
        //         setData(res.recordList)
        //         setPage({
        //             ...pagination,
        //             total: res.totalNumber
        //         })
        //     })
        // } else {
        //     queryTaskListBytaskId({
        //         currentPage: pagination.current - 1,
        //         pageSize,
        //         taskId,
        //         connectedResultTypeList: resultType[0] === 0 ? [] : resultType,
        //         intentionLevelList: intention[0] === 0 ? [] : intention
        //     }).then(res => {
        //         setData(res.recordList)
        //         setPage({
        //             ...pagination,
        //             total: res.totalNumber
        //         })
        //     })
        // }
    }, [taskId])
    // 接听情况
    function onResultChange(list) {
        // console.log('type: ', type)
        setResult(list)
        // console.log('resultType-筛选: ', resultType)
        // console.log('onResultChange: ', list)
        setIntention([])
        setPage({
            ...pagination,
            current: 1,
            total: 0
        })
        
        sx(list, 'jt')      
        
        
    }
    // 意向筛选
    function onIntentionChange(list) {
        // return
        // console.log('onIntentionChange: ', list)
        setIntention(list)
        // console.log('intention: ', intention)
        setResult([])
        setPage({
            ...pagination,
            current: 1,
            total: 0
        })
        sx(list, 'yx')
    }

    // 用于筛选
    const sx = (list, type) => {
        const param_a = {
            areaCode: null,
            streetCode: null,
            neighborhoodCode: null
        }        
        // 3是区  1是街道  2是居委
        if (area) {
            param_a.areaCode = null
            param_a.streetCode = null
            param_a.neighborhoodCode = null
            if (area.length >= 6 && area.length <= 7) {
                param_a.areaCode = area
            }
            if (area.length >= 9 && area.length <= 10) {
                // alert(area.length)
                param_a.streetCode = area
            }
            if (area.length >= 12) {
                param_a.neighborhoodCode = area
            }
        }

        const loginName = localStorage.getItem("loginName")
  
        if (loginName) {
          if (loginName.length >= 6 && loginName.length <= 7) {
            //   alert(loginName)
              param_a.areaCode = loginName
          }
          if (loginName.length >= 9 && loginName.length <= 10) {
              // alert(area.length)
              param_a.streetCode = loginName
          }
          if (loginName.length >= 12) {
              param_a.neighborhoodCode = loginName
          }
        }
        let param = {}
        if(type === 'yx'){
            param = {
                ...param_a,
                intentionLevelList: list[0] === 0 ? [] : list
            }
        } else {
            param = {
                ...param_a,
                connectedResultTypeList: list[0] === 0 ? [] : list
            }
        }

        console.log('sx-param: ', param)

        queryTaskItemListByArea({
            ...param,            
            date: day,
            currentPage: pagination.current - 1,
            pageSize
        }).then(res => {
            if (res.recordList) {
                setData(res.recordList)
                setPage({
                    ...pagination,
                    total: res.totalNumber
                })
            }
        })
    }

    function onPageChange(pagination) {
        // console.log('onPageChange - pagination: ', pagination)
        // console.log('type: ', type)
        // console.log('taskId: ', taskId)
        // queryTaskItemListByArea({
        //     currentPage: pagination.current - 1,
        //     pageSize,
        //     areaCode: taskId,
        //     connectedResultTypeList: resultType[0] === 0 ? [] : resultType,
        //     intentionLevelList: intention[0] === 0 ? [] : intention
        // }).then(res => {
        //     setData(res.recordList)
        //     setPage({
        //         ...pagination,
        //         total: res.totalNumber
        //     })
        // })
        taskItemListByArea(pagination.current)
        
    }

    const columns = [{
        title: '电话号码',
        dataIndex: 'phone',
        fixed: true,
        width: 142,
        render(val) {
            return (
                <Fragment>
                    <span>{phoneVisible ? val : (val.substr(0, 3) + '****' + val.substr(8, 4))}&nbsp;&nbsp;</span>
                    <Icon type="copy" theme="filled" onClick={() => {
                        copyTxt(val)
                        Toast.success('号码已复制！', 1);
                    }} />
                </Fragment>
            )
        }
    }, {
        title: '姓名',
        dataIndex: 'customerName'
    }, {
        title() {
            return (
                <div className="tasktable-header">
                    <span>接听情况</span>
                    <Picker
                        extra=''
                        data={ResultTypePairs}
                        title="接听情况"
                        cols={1}
                        onOk={e => onResultChange(e)}
                    >
                        <img src={filterImg} width="16" alt="" />
                    </Picker>

                </div>)
        },
        dataIndex: 'connectedResultType',
        width: 106,
        render(val) {
            // console.log('106: ', val)
            return ConnectedResultTypeMap[val]
        }
    }, {
        title() {
            return (
                <div className="tasktable-header">
                    <span>意向等级</span>
                    <Picker
                        extra=''
                        data={IntentionLevel}
                        title="意向等级"
                        cols={1}
                        onOk={e => onIntentionChange(e)}
                    >
                        <img src={filterImg} width="16" alt="" />
                    </Picker>

                </div>)
        },
        width: 106,
        dataIndex: 'intentionLevel',
        render(val) {
            if (val === undefined) return '-'
            return String.fromCharCode('A'.charCodeAt(0) + val - 1)
        }
    }, {
        title: '操作',
        dataIndex: 'id',
        render(val, row) {
            if (row.connectedResultType === 1) {
                return <div onClick={() => {
                    window.sessionStorage.setItem('row', JSON.stringify(row))
                    window.location.href = `/task/${val}`
                }}>详情</div>
            }
            return '-'
        }
    }]
    // console.log('render', pagination)
    if (type === 'HZ') {
        columns.splice(4, 0, {
            title: '居委',
            dataIndex: 'area5'
        })
    }
    return (
        <Table
            bordered
            columns={columns}
            dataSource={data}
            scroll={{ x: '200%' }}
            pagination={pagination}
            onChange={onPageChange}
            locale={{ emptyText: '暂无数据' }}
        />
    )
}

export default TaskTable