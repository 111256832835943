import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"

import Customer from './Customer'
import Recoard from './Recoard'
import CallDetail from './CallDetail'
import Content from './Content'
import Remark from './Remark'

import { getTaskDetail, getTaskDetail2 } from '../api'

import './taskDetail.css'

function TaskDetail() {
    let { id } = useParams();
    const [data, setData] = useState({})
    const [data2, setData2] = useState({})
    useEffect(() => {
        getTaskDetail({ taskItemId: id }).then(res => {
            setData(res)
        })
        getTaskDetail2({ taskItemId: id }).then(res => {
            setData2(res)
        })
    }, [id])
    return (
        <div className="task-detail">
            <Customer data={data2}/>
            <Recoard data={data} />
            <CallDetail data={data2} />
            <Content data={data} />
            <Remark />
        </div>
    )
}
export default TaskDetail