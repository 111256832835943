import React, { createContext,useReducer } from 'react';

export const AreaContext = createContext({})

export const UPDATE_AREA = "UPDATE_AREA"
export const UPDATE_TOPDATA = "UPDATE_TOPDATA"

const reducer= (state,action)=>{
    console.log('reducer')
    console.log('action.area: ', action.area)
    console.log('action.area: ', action.area)
    switch(action.type){        
        case UPDATE_AREA:            
            localStorage.setItem("area", action.area)
            return action.area
        default:
            return state
    }
}


export const Area = props=>{
    console.log('Area - props')

    const [area,dispatch]=useReducer(reducer, '')
    console.log('Area-selectArea')
    return (
        <AreaContext.Provider value={{area, dispatch}}>
            {props.children}
        </AreaContext.Provider>
    )
}