import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"

import { Tabs, Button, Toast } from 'antd-mobile'
import { Input } from 'antd'

import { saveRemark, queryRemarks } from '../api'

import 'antd/es/input/style/css'

const Textarea = Input.TextArea

function Remark() {
    const [remark, setRemark] = useState('')
    const [remarkList, setList] = useState([])
    const { id } = useParams();

    useEffect(() => {
        queryRemarks({ taskItemId: id }).then(res => {
            setList(res)
        })
    }, [id])

    const tabs = [
        { title: '添加备注' },
        { title: '历史备注' }
    ]


    function save() {
        saveRemark({
            taskItemId: id,
            checkResult: remark
        }).then((res) => {
            if (res.resultMessageEnum === '0000') {
                Toast.success('保存成功！', 1)
                setRemark('')
                queryRemarks({ taskItemId: id }).then(res => {
                    setList(res)
                })
            }

        })
    }

    return (
        <div className="task-customer">
            <div className="task-header">
                <span>备注</span>
            </div >
            <div>
                <Tabs tabs={tabs} initialPage={0} animated={false} useOnPan={false}>
                    <div style={{ padding: '24px 16px' }}>
                        <Textarea
                            value={remark}
                            autoHeight
                            rows={5}
                            maxLength={200}
                            placeholder='输入备注内容'
                            onChange={(e) => { setRemark(e.target.value) }}
                        />
                        <div class="remark-num">{remark.length}/200</div>
                        <Button type="primary" onClick={save}>保存</Button>
                    </div>
                    <div style={{ padding: '24px 16px' }}>
                        {remarkList.map(r => {
                            return <div style={{marginBottom: 10, padding: '6px', border: '1px solid #ccc', borderRadius: 4}}>{r.result}</div>
                        })}

                    </div>

                </Tabs>
            </div>
        </div>
    )
}
export default Remark